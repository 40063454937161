// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCBZDTJ7dejZ5JlJlTQOA_TTRS_dNDssAY",
  authDomain: "datarush-ke.firebaseapp.com",
  projectId: "datarush-ke",
  storageBucket: "datarush-ke.appspot.com",
  messagingSenderId: "28107165463",
  appId: "1:28107165463:web:ca8c643538a0eab012b152",
  measurementId: "G-BJE4BPZSMD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



export {app};
export {analytics}