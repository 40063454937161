import './App.css';
import './Responsive.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import components
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard';
import Drivers from './Pages/Drivers';
import Finance from './Pages/Finance';
import Trips from './Pages/Trips';
import Users from './Pages/Users'; 
import Vehicles from './Pages/Vehicles';
import LogisticsOrders from './Pages/LogisticsOrders';
import LogisticsPricing from './Pages/LogisticsPricing';
import Customers from './Pages/Customers';
// 
import DashboardClient from './Pages/Client/Dashboard';
import PlaceAnOrder from './Pages/Client/PlaceAnOrder';
import MyOrders from './Pages/Client/MyOrders';
import Invoices from './Pages/Client/Invoices';
import TrackDriver from './Pages/Client/TrackDriver';
import TrackMe from './Pages/Client/TrackMe';
// import ViewOrder from './Pages/Client/ViewOrder';
import PlaceAnOrderDriver from './Pages/Driver/PlaceAnOrderDriver';
import MyOrdersDriver from './Pages/Driver/MyOrdersDriver';
import Deliveries from './Pages/Deliveries';
import AdminTrack from './Pages/AdminTrack';
import ViewOrder from './Pages/ViewOrder';
import ViewOrder2 from './Pages/Client/ViewOrder';
import ViewWaybill from './ViewWaybill';
import HomePage from './Pages/HomePage';
import NewHome from './NewHome';
import DelRequest from './Pages/DelRequest';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>

              <Route path="/login" element={<Login />} />
              <Route path="/" element={<NewHome />} />
              <Route path="/old" element={<HomePage />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/trips" element={<Trips />} />
              <Route path="/finance" element={< Finance/>} />
              <Route path="/users" element={<Users/>} />

              <Route path="/vehicles" element={<Vehicles/>} />
              <Route path="/orders" element={<LogisticsOrders/>} />
              <Route path="/pricing-plans" element={<LogisticsPricing/>} />
              <Route path="/subscriptions" element={<Customers />} />
              <Route path="/deliveries" element={<Deliveries />} />
              <Route path="/track" element={<AdminTrack />} />
              <Route path="/delRequests" element={<DelRequest />} />

              <Route path="/view-order" element={<ViewOrder />} />
              <Route path="/view-waybill" element={<ViewWaybill  />} />
              <Route path="/client/dashboard" element={<DashboardClient/>} />
              <Route path="/client/place-an-order" element={<PlaceAnOrder/>} />
              <Route path="/client/all-orders" element={<MyOrders />} />
              
              <Route path="/client/invoices" element={<Invoices />} />
              <Route path="/client/trackdriver" element={<TrackMe />} />
              <Route path="/client/vieworder" element={<ViewOrder2 />} />


              

              {/* Driver */}
              <Route path="/driver/place-an-order" element={<PlaceAnOrderDriver/>} />
              <Route path="/driver/my-orders" element={<MyOrdersDriver/>} />

          </Routes>
      </BrowserRouter>  
    </div>
  );
}

export default App;
