import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { getFirestore } from '@firebase/firestore';
import { collection, query, getDocs, where } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';

function AdminTrackComp() {
    const [driverLocation, setDriverLocation] = useState({ lat: -1.3049444, lng: 36.8118173 });
    const [myData, setMyData] = useState(null); // State to store the received data
    
  
  
    const db = getFirestore();
    const navigate = useNavigate();
    const location = useLocation();
  
    // Receive data from the previous page
    useEffect(() => {
      const data = location.state?.myData;
      setMyData(data);
    }, [location.state]);
  
  
    const [isMapVisible, setIsMapVisible] = useState(false);
  
  
    useEffect(() => {
      // Fetch driver's location from Firebase when myData changes
      const fetchData = async () => {
        if (myData) {
          try {
            const querySnapshot = await getDocs(query(collection(db, 'drivers'), where('userId', '==', myData)));
  
            querySnapshot.forEach((doc) => {
              const latitude = doc.data().lattitude;  // Corrected field name
              const longitude = doc.data().longitude;
  
              console.log('Firebase Data:', { latitude, longitude });
  
              let floatValueLat = parseFloat(latitude);
              let floatValueLong = parseFloat(longitude);
  
              setDriverLocation({ lat: floatValueLat, lng: floatValueLong });
              setIsMapVisible(!isMapVisible);
              
  
              
            });
          } catch (error) {
            console.error('Error fetching data from Firebase:', error);
          }
        }
  
  
      };
  
      // Initial fetch
      fetchData();
  
      // Set up interval to fetch data every 5 seconds
      const intervalId = setInterval(fetchData, 5000);
  
      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }, [myData, db]);
  
    ///
  
    const Marker = ({ text }) => (
      <div style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}>
        🚗 {text}
      </div>
    );
  
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        console.log("hello")
        window.location.reload()
      }, 11000);
      return () => clearInterval(intervalId);
    }, []); 
  
  
  
  
    console.log("driver log " + driverLocation.lng + ", " + driverLocation.lat )
  
   
  
    return (
  
      
      <div>
  
      {isMapVisible && (
        <div style={{ height: '100vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCHsbAdADaDUvGnyoGDExFSTRzpd--NzcI', mapTypeId: 'hybrid' }}
            defaultCenter={{ lat: driverLocation?.lat || 0, lng: driverLocation?.lng || 0 }}
            defaultZoom={17}
          >
            {driverLocation && (
              <Marker
                key="driver"
                lat={driverLocation.lat}
                lng={driverLocation.lng}
                text="Driver's Location"
              />
            )}
          </GoogleMapReact>
        </div>
      )}
    </div>
    );
  }
export default AdminTrackComp